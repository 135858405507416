
// export const wrapPageElement = ({element}) => (
//     <AnimatePresence
//         mode="wait"
//         onExitComplete={() => {
//         document
//             .body
//             .classList
//             .remove('overflow-hidden')
//     }}>{element}</AnimatePresence>
// );

const transitionDelay = 500;

export const shouldUpdateScroll = ({routerProps: {
        location
    }, getSavedScrollPosition}) => {
    if (location.action === "PUSH") {
        window.setTimeout(() => window.scrollTo(0, 0), transitionDelay);
    } else {
        const savedPosition = getSavedScrollPosition(location);
        window.setTimeout(() => window.scrollTo(...(savedPosition || [0, 0])), transitionDelay);
    }
    return false;
};

export const onRouteUpdate = ({ location }) => {
    if (location.pathname === '/') {
      if (!window.IntentWave) {
        window.IntentWave = {};
        window.IntentWave.track = function() {
          window.IntentWave.track.q = window.IntentWave.track.q || [];
          window.IntentWave.track.q.push(arguments);
        };
  
        // Load the tracking script
        (function() {
          var u = 'https://cdn.intentwave.com/';
          var d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
          g.type = 'text/javascript';
          g.async = true;
          g.defer = true;
          g.src = u + 'track.js';
          g.setAttribute('data-name', 'IntentWave');
          g.setAttribute('data-id', 'c2545690-ad9d-4707-acd9-a3ea2a99ecb3');
          s.parentNode.insertBefore(g, s);
        })();
      }
    }
  };